import { SettingOutlined, AppstoreOutlined } from "@ant-design/icons";

const systemRouters = [
  {
    key: 100,
    pathname: "/main/system",
    label: "系统管理",
    icon: <SettingOutlined />,
    children: [
      {
        key: 101,
        pathname: "/main/system/auth",
        label: "角色权限",
        icon: <AppstoreOutlined />,
        component: "./pages/system/auth",
        children: [],
      },
      {
        key: 102,
        pathname: "/main/system/users",
        label: "用户管理",
        icon: <AppstoreOutlined />,
        component: "./pages/system/users",
        children: [],
      },
      {
        key: 103,
        pathname: "/main/system/version",
        label: "版本管理",
        icon: <AppstoreOutlined />,
        component: "./pages/system/version",
        children: [],
      },
      {
        key: 104,
        pathname: "/main/system/setting",
        label: "系统设置",
        icon: <AppstoreOutlined />,
        component: "./pages/system/setting",
        children: [],
      },
    ],
  },
];
const gameRouters = [
  {
    key: 200,
    pathname: "/main/game",
    label: "游戏管理",
    icon: <SettingOutlined />,
    children: [
      {
        key: 201,
        pathname: "/main/game/games",
        label: "游戏列表",
        icon: <AppstoreOutlined />,
        component: "./pages/game/games",
        children: [
          {
            key: 20101,
            pathname: "/main/game/games/keystroke",
            label: "游戏键盘",
            icon: <AppstoreOutlined />,
            component: "./pages/game/keystroke",
            children: [],
            hidden: true,
          },
        ],
      },
      {
        key: 202,
        pathname: "/main/game/tag",
        label: "游戏标签",
        icon: <AppstoreOutlined />,
        component: "./pages/game/gameTag",
        children: [],
      },
      {
        key: 203,
        pathname: "/main/game/classify",
        label: "游戏分类",
        icon: <AppstoreOutlined />,
        component: "./pages/game/gameClassify",
        children: [],
      },
      {
        key: 204,
        pathname: "/main/game/indexConfig",
        label: "首页配置",
        icon: <AppstoreOutlined />,
        component: "./pages/game/indexConfig",
        children: [],
      },
      {
        key: 205,
        pathname: "/main/game/queueTimeSetting",
        label: "排队时长设置",
        icon: <AppstoreOutlined />,
        component: "./pages/game/queueTimeSetting",
        children: [],
      },
      {
        key: 206,
        pathname: "/main/game/queuePopSetting",
        label: "排队弹窗设置",
        icon: <AppstoreOutlined />,
        component: "./pages/game/queuePopSetting",
        children: [],
      },
    ],
  },
];
const chargeRouters = [
  {
    key: 300,
    pathname: "/main/charge",
    label: "付费管理",
    icon: <SettingOutlined />,
    children: [
      {
        key: 301,
        pathname: "/main/charge/vip",
        label: "会员售卖",
        icon: <AppstoreOutlined />,
        component: "./pages/charge/vip",
        children: [],
      },
      {
        key: 302,
        pathname: "/main/charge/duration",
        label: "峰值时长售卖",
        icon: <AppstoreOutlined />,
        component: "./pages/charge/duration",
        children: [],
      },
      {
        key: 303,
        pathname: "/main/charge/account",
        label: "游戏账号礼包",
        icon: <AppstoreOutlined />,
        component: "./pages/charge/account",
        children: [],
      },
      {
        key: 304,
        pathname: "/main/charge/timeCard",
        label: "时效畅玩卡",
        icon: <AppstoreOutlined />,
        component: "./pages/charge/timeCard",
        children: [],
      },
      {
        key: 305,
        pathname: "/main/charge/newGiftKit",
        label: "新人礼包",
        icon: <AppstoreOutlined />,
        component: "./pages/charge/newGiftKit",
        children: [],
      },
    ],
  },
];

const suggesRouters = [
  {
    key: 400,
    pathname: "/main/sugges",
    label: "信息收集",
    icon: <SettingOutlined />,
    children: [
      {
        key: 401,
        pathname: "/main/sugges/application",
        label: "游戏申请",
        icon: <AppstoreOutlined />,
        component: "./pages/sugges/application",
        children: [],
      },
      {
        key: 402,
        pathname: "/main/sugges/feedback",
        label: "问题反馈",
        icon: <AppstoreOutlined />,
        component: "./pages/sugges/feedback",
        children: [],
      },
      {
        key: 403,
        pathname: "/main/sugges/report",
        label: "数据上报",
        icon: <AppstoreOutlined />,
        component: "./pages/sugges/report",
        children: []
      }
    ],
  },
];

const operateRouters = [
  {
    key: 500,
    pathname: "/main/operate",
    label: "运营管理",
    icon: <SettingOutlined />,
    children: [
      {
        key: 501,
        pathname: "/main/operate/redPacket",
        label: "红包管理",
        icon: <AppstoreOutlined />,
        component: "./pages/operate/redPacket",
        children: [],
      },
      // {
      //   key: 502,
      //   pathname: "/main/operate/queue",
      //   label: "排队管理",
      //   icon: <AppstoreOutlined />,
      //   component: "./pages/operate/queue",
      //   children: [],
      // },
      {
        key: 503,
        pathname: "/main/operate/userinvite",
        label: "邀请码",
        icon: <AppstoreOutlined />,
        component: "./pages/operate/userinvite",
        children: [],
      },
      {
        key: 504,
        pathname: "/main/operate/invite",
        label: "邀请记录",
        icon: <AppstoreOutlined />,
        component: "./pages/operate/invite",
        children: [],
      },
      {
        key: 505,
        pathname: "/main/operate/platformAccount",
        label: "平台账号",
        icon: <AppstoreOutlined />,
        component: "./pages/operate/platformAccount",
        children: [],
      },
    ],
  },
];

const userRouters = [
  {
    key: 600,
    pathname: "/main/member",
    label: "用户管理",
    icon: <SettingOutlined />,
    children: [
      {
        key: 601,
        pathname: "/main/member/member",
        label: "用户信息",
        icon: <AppstoreOutlined />,
        component: "./pages/member/member",
        children: [],
      },
      {
        key: 602,
        pathname: "/main/member/gameRecord",
        label: "游戏记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/gameRecord",
        children: [],
      },
      {
        key: 603,
        pathname: "/main/member/vipRecord",
        label: "会员售卖记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/vipRecord",
        children: [],
      },
      {
        key: 604,
        pathname: "/main/member/durationRecord",
        label: "时长售卖记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/durationRecord",
        children: [],
      },
      {
        key: 605,
        pathname: "/main/member/accountRecord",
        label: "游戏账号售卖记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/accountRecord",
        children: [],
      },
      {
        key: 606,
        pathname: "/main/member/timeCardRecord",
        label: "畅玩卡售卖记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/timeCardRecord",
        children: [],
      },
      {
        key: 607,
        pathname: "/main/member/newGiftKitRecord",
        label: "新人礼包售卖记录",
        icon: <AppstoreOutlined />,
        component: "./pages/member/newGiftKitRecord",
        children: [],
      },
    ],
  },
];

const dataRouters = [
  {
    key: 700,
    pathname: "/main/data",
    label: "数据明细",
    icon: <SettingOutlined />,
    children: [
      {
        key: 701,
        pathname: "/main/data/vipDetail",
        label: "VIP明细",
        icon: <AppstoreOutlined />,
        component: "./pages/data/vipDetail",
        children: [],
      },
      {
        key: 702,
        pathname: "/main/data/durationDetail",
        label: "时长明细",
        icon: <AppstoreOutlined />,
        component: "./pages/data/durationDetail",
        children: [],
      },
      {
        key: 703,
        pathname: "/main/data/gradeDetail",
        label: "等级明细",
        icon: <AppstoreOutlined />,
        component: "./pages/data/gradeDetail",
        children: [],
      },
      {
        key: 704,
        pathname: "/main/data/timeCardDetail",
        label: "畅玩卡明细",
        icon: <AppstoreOutlined />,
        component: "./pages/data/timeCardDetail",
        children: [],
      },
    ],
  },
];

const convertRouters = [
  {
    key: 800,
    pathname: "/main/convert",
    label: "数据统计",
    icon: <SettingOutlined />,
    children: [
      {
        key: 801,
        pathname: "/main/convert/base",
        label: "基础数据",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/base",
        children: [],
      },
      {
        key: 802,
        pathname: "/main/convert/pay",
        label: "付费数据",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/pay",
        children: [],
      },
      {
        key: 803,
        pathname: "/main/convert/host",
        label: "机器统计",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/host",
        children: [],
      },
      {
        key: 805,
        pathname: "/main/convert/memberData",
        label: "用户活动数据",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/memberData",
        children: [],
      }, {
        key: 806,
        pathname: "/main/convert/gameData",
        label: "游戏启动数据",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/gameData",
        children: [],
      }, {
        key: 807,
        pathname: "/main/convert/funnel",
        label: "大盘漏斗数据",
        icon: <AppstoreOutlined />,
        component: "./pages/convert/funnel",
        children: []
      }
    ],
  },
];

// const actualTimeRouters = [
//   {
//     key: 900,
//     pathname: "/main/actualTime",
//     label: "实时数据",
//     icon: <SettingOutlined />,
//     children: [{
//       key: 901,
//       pathname: "/main/actualTime/register",
//       label: "注册用户",
//       icon: <AppstoreOutlined />,
//       component: "./pages/actualTime/register",
//       children: [],
//     },{
//       key: 902,
//       pathname: "/main/actualTime/recharge",
//       label: "用户充值",
//       icon: <AppstoreOutlined />,
//       component: "./pages/actualTime/recharge",
//       children: [],
//     },{
//       key: 904,
//       pathname: "/main/actualTime/queue",
//       label: "游戏排队",
//       icon: <AppstoreOutlined />,
//       component: "./pages/actualTime/queue",
//       children: [],
//     },{
//       key: 905,
//       pathname: "/main/actualTime/host",
//       label: "主机消耗",
//       icon: <AppstoreOutlined />,
//       component: "./pages/actualTime/host",
//       children: [],
//     }],
//   },
// ];

export const initRouters = function (authority) {
  let routersObj = {};
  let routerArray = [
    ...systemRouters,
    ...gameRouters,
    ...chargeRouters,
    ...suggesRouters,
    ...operateRouters,
    ...userRouters,
    ...dataRouters,
    ...convertRouters,
    // ...actualTimeRouters,
  ];
  let routerAuthList = [];
  let routerAuthMaps = [];
  const convertMenu = (item, parent) => {
    return {
      ...item,
      parent: parent
        ? {
          pathname: parent.pathname,
          label: parent.label,
          key: parent.key,
          icon: parent.icon,
          parent: parent.parent,
        }
        : null,
      children: null,
    };
  };
  const foreachMenus = (children, parent) => {
    children.forEach((item) => {
      let menu = convertMenu(item, parent);
      routersObj[menu.key] = menu;
      if (item.children && item.children.length > 0) {
        var hasAuth = authority.filter((au) => au === menu.key);
        if (hasAuth.length === 0) {
          foreachMenus(item.children, null);
        } else {
          foreachMenus(item.children, menu);
        }
      }
    });
  };
  foreachMenus(routerArray);
  //权限内容
  for (const key in routersObj) {
    let menuItem = routersObj[key];
    var hasAuth = authority.filter((au) => au === menuItem.key);
    if (hasAuth.length === 0 && !menuItem.hidden) {
      delete routersObj[key];
    } else {
      routerAuthMaps.push(menuItem);
    }
  }
  //组织树
  for (const key in routersObj) {
    let menuItem = routersObj[key];
    if (menuItem.parent) {
      var parentItem = routersObj[menuItem.parent.key];
      if (parentItem) {
        if (!parentItem.children) parentItem.children = [];
        parentItem.children.push(menuItem);
      }
    }
  }
  for (const key in routersObj) {
    if (routersObj[key].parent) {
      delete routersObj[key];
    } else {
      routerAuthList.push(routersObj[key]);
    }
  }

  return { routersObj, routerArray, routerAuthList, routerAuthMaps };
};
