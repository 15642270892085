import request from "./request";

const HTTP = {
  _login: (params) => {
    return request.post("/api/admin/login", params);
  },
  _lvs: (params) => {
    return request.post("/api/admin/lvs", params);
  },
  _getAuth: (params) => {
    return request.post("/api/admin/auth/user/getAuth", params);
  },
  _getRoles: (params) => {
    return request.post("/api/admin/auth/user/roles", params);
  },
  _getAuthByRole: (params) => {
    return request.post("/api/admin/auth/user/getAuth", params);
  },
  _setAuthByRole: (params) => {
    return request.post("/api/admin/auth/user/setRoleAuth", params);
  },
  _getUsers: (params) => {
    return request.post("/api/admin/auth/user/list", params);
  },
  _createAdminUser: (params) => {
    return request.post("/api/admin/auth/user/register", params);
  },
  _removeAdminUser: (params) => {
    return request.post("/api/admin/auth/user/remove", params);
  },
  _getVersions: (params) => {
    return request.post("/api/admin/auth/version/list", params);
  },
  _saveVersion: (params) => {
    return request.post("/api/admin/auth/version/save", params);
  },
  _removeVersion: (params) => {
    return request.post("/api/admin/auth/version/remove", params);
  },
  _getConfig: (params) => {
    return request.post("/api/admin/auth/system/config", params);
  },
  _setConfig: (params) => {
    return request.post("/api/admin/auth/system/setConfig", params);
  },
  _getOSSToken: (params) => {
    return request.post("/api/admin/auth/system/getOSSToken", params)
  },
  _getGameTags: (params) => {
    return request.post("/api/admin/auth/game/tag/list", params);
  },
  _setGameTag: (params) => {
    return request.post("/api/admin/auth/game/tag/save", params);
  },
  _getGameClassifys: (params) => {
    return request.post("/api/admin/auth/game/classify/list", params);
  },
  _setGameClassify: (params) => {
    return request.post("/api/admin/auth/game/classify/save", params);
  },
  _getGames: (params) => {
    return request.post("/api/admin/auth/game/list", params);
  },
  _saveGame: (params) => {
    return request.post("/api/admin/auth/game/save", params);
  },
  _searchGame: (params) => {
    return request.post("/api/admin/auth/game/search", params);
  },
  _recommendTop: (params) => {
    return request.post("/api/admin/auth/recommend/top", params);
  },
  _recommendTopSave: (params) => {
    return request.post("/api/admin/auth/recommend/topSave", params);
  },
  _recommendTopRemove: (params) => {
    return request.post("/api/admin/auth/recommend/topRemove", params);
  },
  _recommendMore: (params) => {
    return request.post("/api/admin/auth/recommend/more", params);
  },
  _recommendMoreSave: (params) => {
    return request.post("/api/admin/auth/recommend/moreSave", params);
  },
  _recommendMoreRemove: (params) => {
    return request.post("/api/admin/auth/recommend/moreRemove", params);
  },
  _recommendClassify: (params) => {
    return request.post("/api/admin/auth/recommend/classify", params);
  },
  _recommendClassifySave: (params) => {
    return request.post("/api/admin/auth/recommend/classifySave", params);
  },
  _recommendClassifyRemove: (params) => {
    return request.post("/api/admin/auth/recommend/classifyRemove", params);
  },
  _chargeVIP: (params) => {
    return request.post("/api/admin/auth/charge/vip", params);
  },
  _chargeVIPSave: (params) => {
    return request.post("/api/admin/auth/charge/vip/save", params);
  },
  _chargeDuration: (params) => {
    return request.post("/api/admin/auth/charge/duration", params);
  },
  _chargeDurationSave: (params) => {
    return request.post("/api/admin/auth/charge/duration/save", params);
  },
  _chargeAccount: (params) => {
    return request.post("/api/admin/auth/charge/account", params);
  },
  _chargeAccountSave: (params) => {
    return request.post("/api/admin/auth/charge/account/save", params);
  },
  _chargeAccountList: (params) => {
    return request.post("/api/admin/auth/charge/account/list", params);
  },
  _chargeAccountRemove: (params) => {
    return request.post("/api/admin/auth/charge/account/list/remove", params);
  },
  _gameApplicationList: (params) => {
    return request.post("/api/admin/auth/sugges/gameApplication/list", params);
  },
  _feedbackList: (params) => {
    return request.post("/api/admin/auth/sugges/feedback/list", params);
  },
  _feedbackDone: (params) => {
    return request.post("/api/admin/auth/sugges/feedback/done", params);
  },
  _getReportList: (params) => {
    return request.post("/api/admin/auth/sugges/report/list", params)
  },
  _redPacketList: (params) => {
    return request.post("/api/admin/auth/redPacket/list", params);
  },
  _redPacketDone: (params) => {
    return request.post("/api/admin/auth/redPacket/save", params);
  },
  _QueueList: (params) => {
    return request.post("/api/admin/auth/queue/list", params);
  },
  _QueueKick: (params) => {
    return request.post("/api/admin/auth/queue/kick", params);
  },
  _getMemberList: (params) => {
    return request.post("/api/admin/auth/member/memberList", params);
  },
  _getMemberDetail: (params) => {
    return request.post("/api/admin/auth/member/memberDetail", params)
  },
  _getMemberEnable: (params) => {
    return request.post("/api/admin/auth/member/memberEnable", params);
  },
  _getGameRecord: (params) => {
    return request.post("/api/admin/auth/member/gameRecord", params);
  },
  _getVipRecord: (params) => {
    return request.post("/api/admin/auth/member/vipRecord", params);
  },
  _getDurationRecord: (params) => {
    return request.post("/api/admin/auth/member/durationRecord", params);
  },
  _getAccountRecord: (params) => {
    return request.post("/api/admin/auth/member/accountRecord", params);
  },
  _getUserDurationDetail: (params) => {
    return request.post("/api/admin/auth/data/durationDetail", params);
  },
  _getRedPacketRecord: (params) => {
    return request.post("/api/admin/auth/redPacket/record", params);
  },
  _getUserInviteRecord: (params) => {
    return request.post("/api/admin/auth/data/userInviteRecord", params);
  },
  _vipRecordRefund: (params) => {
    return request.post("/api/admin/auth/member/vipRecord/refund", params);
  },
  _durationRecordRefund: (params) => {
    return request.post("/api/admin/auth/member/durationRecord/refund", params);
  },
  _accountRecordRefund: (params) => {
    return request.post("/api/admin/auth/member/accountRecord/refund", params);
  },
  _getUserInviteList: (params) => {
    return request.post("/api/admin/auth/member/userInviteList", params);
  },
  _getUserInviteSave: (params) => {
    return request.post("/api/admin/auth/member/userInviteSave", params);
  },
  _closeGameRecord: (params) => {
    return request.post("/api/admin/auth/member/closeGameRecord", params);
  },
  _timeCardList: (params) => {
    return request.post("/api/admin/auth/charge/timeCard/list", params);
  },
  _timeCardSave: (params) => {
    return request.post("/api/admin/auth/charge/timeCard/save", params);
  },
  _timeCardShow: (params) => {
    return request.post("/api/admin/auth/charge/timeCard/show", params);
  },
  _newGiftKitList: (params) => {
    return request.post("/api/admin/auth/charge/newGiftKit/list", params);
  },
  _newGiftKitSave: (params) => {
    return request.post("/api/admin/auth/charge/newGiftKit/save", params);
  },
  _newGiftKitShow: (params) => {
    return request.post("/api/admin/auth/charge/newGiftKit/show", params);
  },
  _timeCardRecord: (params) => {
    return request.post("/api/admin/auth/member/timeCardRecord", params);
  },
  _timeCardRecordRefund: (params) => {
    return request.post("/api/admin/auth/member/timeCardRecord/refund", params);
  },
  _newGiftKitRecord: (params) => {
    return request.post("/api/admin/auth/member/newGiftKitRecord", params);
  },
  _newGiftKitRecordRefund: (params) => {
    return request.post("/api/admin/auth/member/newGiftKitRecord/refund", params);
  },
  _getWxPayType: (params) => {
    return request.post("/api/admin/auth/system/getWxPayType", params);
  },
  _setWxPayType: (params) => {
    return request.post("/api/admin/auth/system/setWxPayType", params);
  },
  _getBaseDate: (params) => {
    return request.post("/api/admin/auth/convert/base/data", params);
  },
  _getBaseDurationDate: (params) => {
    return request.post("/api/admin/auth/convert/base/durationData", params);
  },
  _getPayDate: (params) => {
    return request.post("/api/admin/auth/convert/pay/data", params);
  },
  _getHostDate: (params) => {
    return request.post("/api/admin/auth/convert/host/data", params);
  },
  _getHostQueue: (params) => {
    return request.post("/api/admin/auth/convert/host/queue", params);
  },
  _getGameTimeDate: (params) => {
    return request.post("/api/admin/auth/convert/gameTime/data", params);
  },
  _getMemberRegisterDate: (params) => {
    return request.post("/api/admin/auth/convert/member/register", params);
  },
  _getMemberPayDate: (params) => {
    return request.post("/api/admin/auth/convert/member/pay", params);
  },
  _getFunnelDataList: (params) => {
    return request.post("/api/admin/auth/convert/funnel/list", params)
  },
  _getPlatformAccounts: (params) => {
    return request.post("/api/admin/auth/game/platformAccount/list", params);
  },
  _delPlatformAccount: (params) => {
    return request.post("/api/admin/auth/game/platformAccount/remove", params);
  },
  _gameKeyboardList: (params) => {
    return request.post("/api/admin/auth/gameKeyboard/list", params);
  },
  _gameKeyboardSave: (params) => {
    return request.post("/api/admin/auth/gameKeyboard/save", params);
  },
  _gameKeyboardRemove: (params) => {
    return request.post("/api/admin/auth/gameKeyboard/remove", params);
  },
  _getConvertMemberLv: (params) => {
    return request.post("/api/admin/auth/convert/member/lv", params);
  },
  _getConvertMemberAmount: (params) => {
    return request.post("/api/admin/auth/convert/member/amount", params);
  },
  _getConvertGameOpen: (params) => {
    return request.post("/api/admin/auth/convert/game/open", params);
  },
  _gameQueueSet: (params) => {
    return request.post("/api/admin/auth/game/queueSet", params);
  },
  _gameQueueSetEdit: (params) => {
    return request.post("/api/admin/auth/game/queueSetEdit", params);
  },
  _gameProSet: (params) => {
    return request.post("/api/admin/auth/game/proSet", params);
  },
  _gameProSetEdit: (params) => {
    return request.post("/api/admin/auth/game/proSetEdit", params);
  },





};

export default HTTP;
