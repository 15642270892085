import moment from "moment";

const Util = {
  sleep: (delay) => new Promise((resolve) => setTimeout(resolve, delay)),
  //复制内容
  copyStr: (str, message) => {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", str);
    input.select();
    if (document.execCommand) {
      document.execCommand("copy");
      message && message.success("复制成功");
    }
    document.body.removeChild(input);
  },
  //日期格式化
  timeFormat: (n, m) => {
    if (m) return moment(n).format(m);
    return moment(n).format("YYYY-MM-DD HH:mm:ss");
  },
  //文件大小计算
  fileSize: (size) => {
    if (!size) return "0 KB";
    function pow1024(num) {
      return Math.pow(1024, num);
    }
    if (size < pow1024(1)) return size + " B";
    if (size < pow1024(2)) return (size / pow1024(1)).toFixed(2) + " KB";
    if (size < pow1024(3)) return (size / pow1024(2)).toFixed(2) + " MB";
    if (size < pow1024(4)) return (size / pow1024(3)).toFixed(2) + " GB";
    return (size / pow1024(4)).toFixed(2) + " TB";
  },
  //时长计算
  formatDuring: function (mss) {
    var days = parseInt(mss / (1000 * 60 * 60 * 24));
    var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = parseInt((mss % (1000 * 60)) / 1000);
    if (days) return `${days}天${hours}小时${minutes}分${seconds}秒`;
    if (hours) return `${hours}小时${minutes}分${seconds}秒`;
    if (minutes) return `${minutes}分${seconds}秒`;
    return `${seconds}秒`;
  },
  //时长计算
  formatDuration: function (m) {
    var minutes = parseInt(m / 60);
    if (m % 60 > 0) minutes += 1;
    var hours = parseInt(minutes / 60);
    minutes = parseInt(minutes % 60);
    var day = parseInt(hours / 24);
    hours = parseInt(hours % 24);
    var str = "";
    if (day) str += `${day}天`;
    if (hours) str += `${hours}小时`;
    if (minutes) str += `${minutes}分`;
    return str;
  },
  //时长
  formatDurationNew: function (seconds) {
    seconds = parseInt(seconds);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let secs = seconds % 60;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    secs = secs < 10 ? '0' + secs : secs;

    return hours + ':' + minutes + ':' + secs;
  },
  //时长计算
  formatDay: function (d) {
    var days = parseInt(d / (1000 * 60 * 60 * 24));
    if (days < 0) return "0天";
    return `${days}天`;
  },
  //图片上传限制
  beforeUpload: function (file, message) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message && message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message && message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  },
  percent: function (a, b) {
    if (a === 0 || b === 0) {
      return '0.00%'
    }
    return `${(a * 100 / b).toFixed(2)}%`
  }
};

export default Util;
